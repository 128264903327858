
.header { 
  background-color: var(--ion-toolbar-background); /*rgb(119, 121, 117);*/
  --border-style:none;
  height:75px;
  z-index:20;
}

.header-logo {        
  height: 45px;
  width: 45px;
}

.header-menu-icon {
  height:40px;
  width:40px;  
}

.header-desktop-txt {
  text-transform: none;     
  font-size:2vw; 
  height:50px;
  color:green;
}

.header-mobile-txt {
  text-transform: none;     
  font-size:1.5vw; 
}

.header-desktop-menu { 
  --border-style:none;
}

.mobile-menu {
  position:absolute;
  width:100vw;  
  z-index:-1;
  margin-top:-250px;
  
  transition: margin-top ,0.5s ease-in-out;
  color:white;
}

.mobile-menu-item {
  --background:none;
}

.mobile-menu-open {  
  margin-top:325px;  
  --background:none;
}

.mobile-menu-background-color {
  --ion-background-color: linear-gradient(149deg, rgba(156,156,199,1) 0%, rgba(193,193,177,1) 0%, rgba(131,131,123,1) 0%, rgba(0,0,0,1) 17%, rgba(98,98,95,0.989233193277311) 38%, rgba(177,177,184,1) 64%, rgba(204,207,224,0.9360119047619048) 100%);
}

.mobile-menu-nav-btn {
  text-transform: none;  
  --border-coor:none;
  --border-width:0px !important;
  
  width:90%;
  color:white;
}