
span {
  width:95%;
}

.popup-menu-nav-button {
  text-transform: none;  
  --border-coor:none;
  --border-width:0px !important;
  width:90%;
  color:white;
}

.popup-menu-item {
  --background:none;
}

.popup-menu-background-color {
  --ion-background-color: linear-gradient(149deg, rgba(156,156,199,1) 0%, rgba(193,193,177,1) 0%, rgba(131,131,123,1) 0%, rgba(0,0,0,1) 17%, rgba(98,98,95,0.989233193277311) 38%, rgba(177,177,184,1) 64%, rgba(204,207,224,0.9360119047619048) 100%);
  }

.popup-menu {
  position:absolute;
  width:100vw;  
  z-index:10;
  margin-top:-200px;
  
  transition: margin-top ,0.5s ease-in-out;
}

.popup-menu-open {  
  margin-top:75px;  
}

