

ion-content {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    --background: url('../assets/Laptop1.png') 70% 70%;    
  }
  
.main-display-area {
  min-height:90vh;
}

.fab {
 
}

.fab-button {
  --background: none !important;
  --box-shadow: 0;
}

.fab-icon {
  /*
  color: var(--ion-toolbar-background);
  */
  font-size: 64px;
  /*background-color: white;;*/
  color: rgb(59, 56, 56);
  /*background-color: rgb(59, 56, 56);   
  opacity: .6;
  */
}