/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/*@font-face { font-family: "Fahkwang"; src: url("../assets/fonts/Fahkwang"); }
*/
/** Ionic CSS Variables **/
:root {
   /*--ion-font-family: Fahkwang*/
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: white;
	--ion-background-color-rgb: 255,255,255;

	--ion-text-color: #000000;
	--ion-text-color-rgb: 0,0,0;

	--ion-color-step-50: #acdfb7;
	--ion-color-step-100: #a3d4ae;
	--ion-color-step-150: #9ac8a4;
	--ion-color-step-200: #91bc9a;
	--ion-color-step-250: #88b091;
	--ion-color-step-300: #7fa587;
	--ion-color-step-350: #76997d;
	--ion-color-step-400: #6d8d74;
	--ion-color-step-450: #64816a;
	--ion-color-step-500: #5b7661;
	--ion-color-step-550: #516a57;
	--ion-color-step-600: #485e4d;
	--ion-color-step-650: #3f5244;
	--ion-color-step-700: #36473a;
	--ion-color-step-750: #2d3b30;
	--ion-color-step-800: #242f27;
	--ion-color-step-850: #1b231d;
	--ion-color-step-900: #121713;
	--ion-color-step-950: #090c0a;

  --ion-toolbar-color:rgb(19, 5, 5);
  --ion-toolbar-background:#f5fdf7;
  /*--ion-toolbar-background:#B5EBC1;*/
  --app-main-background-color:rgba(75, 71, 71, 0.616);
}


html {
  height: 100%;
  overflow-y: hidden;
  margin: 0px;
}

body {
  height: 100vh;
  overflow-y: hidden;
  margin: 0px;
  background-color: white;
}

body::-webkit-scrollbar {
  display: none;
}

@media (max-width: 800px) {
  .desktop-only {
      display: none;
  }
  .mobile-only {
    width:100%;
    height:100%;
  }
  .img1 {
    height:50px;
  }
}

@media (min-width: 800px) {
  .mobile-only {
      display: none;
  }
  .desktop-only {
    width:100%;
    height:100%;
  }
  .img1 {
    height:100%;
  }
  
}

.enter {
  display:block;
  margin-left: 0px;
  margin-top: 0px;
  width:100%;
  height:100%;
  
  z-index:1;

    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
  }

.exit {
  display:none;
  height:0px;
}


@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}
