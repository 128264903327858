.footer-color {
    background-color: green;
    color:white;        
}

.footer-font-color {
    color:white;
}

.hide-card-border {
    box-shadow: none !important;
}

.footer-btn {    
    --background:white;    
    --background-hover:rgb(141, 216, 141);    
    --background-hover-opacity:.5;
    color:green;
    width:20vw;
    max-width:200px;
}

.footer-btn-mobile {    
    --background:white;    
    --background-hover:rgb(141, 216, 141);    
    --background-hover-opacity:.5;
    color:green;
    width:50vw;
    max-width:200px;
}

.center-img { 
    display: flex;
    align-items: center;
    justify-content: center;    
    max-height:50%;
}


.img {
    min-height:100%;
}

.footer-social-btn {
    height:50px;    
}

.p2c-social {
    max-height:45px;
    max-width:45px;
    min-height:25px;
    min-width:25px;
    color:white;
    background-color:green;    
  }

