
ion-input {
    color: white;
}

ion-toast {
    --color: white;
    --background:rgb(123, 155, 123);
    
}

.contactus-img {
            
    height:100%;
    width:100%;
    background-color: white;
        
}

.contact-form {
    max-width:700px;
    min-height:30vh;
}

.contactus-error {
    color:red;
    font-size:small
}

.contactus-button {
    color: green;
    max-width:100px;
    padding:5px;
}

.contactus-area {
    min-height:75vh;
  }

