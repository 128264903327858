.aboutus-row {
  width: 100%;   
  height:75vh; 
}

.aboutus-row-bottom {
  width: 100%;   
  height:30vh; 
}

.aboutus-img {    
  width:100%;
  background-color: white;
}

.aboutus-grid {
  max-height:30vh;
}
  
.aboutus-social-btn {
  height:50px;    
}

.aboutus-social-icon {
  max-height:50px;
  max-width:50px;
  min-height:25px;
  min-width:25px;
  color:white;
  background-color:white;    
  padding:2px;
}

.aboutus-content-padding {
  min-height:75vh;
}