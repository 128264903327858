
.client-logo {    
    margin:10px;  
    max-width: 90px;
    max-height: 900px;    
}

/*
.clients {      
    max-width: 450px;
    max-height: 450px;    
}
*/

.card-no-border
{
    border-style: none;
    box-shadow:none !important    
}

