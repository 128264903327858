.home-row {    
    width: 100%;   
    height:20vh; 
}


.home-text-light {
    color:rgb(255, 255, 255);
    font-size: 8vw;
}


.home-name {
    display: flex;
    justify-content: center;
    align-items: center;    
    background-color: rgb(59, 56, 56);   
    opacity: .6;
}

.home-content-padding {
    min-height:35vh;
}